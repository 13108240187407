.App {
  text-align: center;
}

strong {
  display:block;
}

p, strong {
  line-height: 1.7;
  max-width: 500px;
  margin: 15px auto;
}

.card-columns {
  margin-top: 30px;
}

.hidden {
  display: none;
}

.loginBtn {
  -webkit-appearance: none;
  border: 0;
  background-color: #0047AB;
  color: #fff;
  padding: 12px 40px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
